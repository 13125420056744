<template>
  <nav>
    <v-list>
      <template v-for="(item, i) in items">
        <v-divider
          :key="`divider-${i}`"
          v-if="i !== 0"
        ></v-divider>
        <template v-if="item.children">
          <v-list-item
            :key="`item-${i}`"
            nuxt
            :to="`/${item.slug}`"
            active-class="current"
            class="group-header"
          >
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
            <v-list-item-icon>
              <v-icon>
                mdi-chevron-down
              </v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-group
            :key="`item-group-${i}`"
            eager
            :value="item.active"
            :class="{current: item.active}"
            disabled
            :append-icon="null"
          >
            <template v-slot:default>
              <template v-for="(child, childI) in item.children">
                <v-divider
                  :key="`divider-${i}-${childI}`"
                  v-if="i !== 0"
                ></v-divider>
                <v-list-item
                  class="pl-11"
                  :key="`item-${i}-${childI}`"
                  nuxt
                  :to="`/${child.slug}`"
                >
                  {{ child.title }}
                </v-list-item>
              </template>
            </template>
          </v-list-group>
        </template>
        <v-list-item
          :key="`item-${i}`"
          v-else
          nuxt
          :to="`/${item.slug}`"
          active-class="current"
        >
          {{ item.title }}
        </v-list-item>
      </template>
    </v-list>
  </nav>
</template>

<script>
	export default {
		name: 'ProductCategoryOverviewMenu',
		data() {
			return {
        items: this.$store.state.productCategoryLinks,
			}
		}
	}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  .v-list-item {
    &.group-header {
      text-decoration: none;

      .v-list-item__icon {
        margin-bottom: 0;
      }

      &.current {
        .v-list-item__title {
          text-decoration: underline;
        }
      }
    }
  }

  ::v-deep .v-list-group__header {
    display: none;  // Doesn't appear to be a way to do it via Vuetify
  }

  .v-list-group {
    &.current {
      color: map-get($brand-colors, 'blue');
    }
  }

  .v-list-item--active {
    color: map-get($brand-colors, 'blue');
    text-decoration: underline;

    &:before {
      display: none;
    }
  }
</style>

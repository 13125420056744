<template>
  <section class="carousel" v-editable="blok">
    <div class="previous-slide" @click="previousSlide">
      <transition name="fade">
        <v-img
          v-if="previousImage"
          :key="previousImage"
          itemprop="image"
          :src="previousImage | resize('132x0')"
          :lazy-src="previousImage | resize('10x0')"
          :srcset="srcset(previousImage)"
          :aspect-ratio="aspectRatio"
          sizes="auto"
        />
      </transition>
      <v-icon>icon-arrow-left</v-icon>
    </div>
    <div class="swiper">
      <div ref="carousel" v-swiper:carouselSwiper="swiperOptions">
        <div class="swiper-wrapper">
          <div
            v-for="(slide, index) in blok.items"
            :key="index"
            class="swiper-slide"
          >
            <v-img
              v-if="slide.image"
              itemprop="image"
              :src="slide.image | resize('726x0')"
              :lazy-src="slide.image | resize('132x0')"
              :srcset="srcset(slide.image)"
              :aspect-ratio="aspectRatio"
              sizes="auto"
            >
              <template slot="placeholder">
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular indeterminate color="#00A8E7"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <div class="body">
              <div class="text">
                <h1 v-if="slide.title" data-swiper-parallax="-200" v-html="formatTitle(slide.title)"></h1>
                <p v-if="slide.text" class="hidden-sm-and-down" data-swiper-parallax="-500">{{ slide.text }}</p>
                <ButtonLink
                  v-if="$storyblok.isLink(slide.link)"
                  :link="slide.link"
                  color="#00A8E7"
                  css-class="mt-3 ml-0 default"
                  data-swiper-parallax="-800"
                ><slot v-if="slide.linkText">{{slide.linkText}}</slot></ButtonLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="next-slide" @click="nextSlide">
      <transition name="fade">
        <v-img
          v-if="nextImage"
          itemprop="image"
          :key="nextImage"
          :src="nextImage | resize('132x0')"
          :lazy-src="nextImage | resize('10x0')"
          :aspect-ratio="aspectRatio"
          sizes="auto"
        />
      </transition>
      <v-icon>icon-arrow-right</v-icon>
    </div>
  </section>
</template>

<script>
	import ButtonLink from '~/components/util/ButtonLink';

	export default {
		name: 'Carousel',
		props: ['blok'],
		components: {ButtonLink},
		data() {
			return {
				previousImage: this.blok.items.length > 1 ? this.blok.items[this.blok.items.length - 1].image : null,
        nextImage: this.blok.items.length > 1 ? this.blok.items[1].image : null,
				swiperOptions: {
					speed: 600,
					lazy: false,
					preloadImages: false,
					effect: 'fade',
					threshold: 5,
					parallax: true,
					loop: false,
					pagination: this.blok.items.length > 1 ? {
						el: '.swiper-pagination',
						clickable: true
					} : false,
					autoplay: this.blok.items.length > 1 ? { delay: 4000 } : false,
					on: {
						slideChange: () => {
							const slides = this.blok.items;
							if (this.carouselSwiper && slides.length > 1) {
                const previousIndex = this.carouselSwiper.realIndex === 0 ? slides.length - 1 : this.carouselSwiper.realIndex - 1
                const nextIndex = this.carouselSwiper.realIndex + 1 === slides.length ? 0 : this.carouselSwiper.realIndex + 1

                setTimeout(() => {
                  this.previousImage = slides[previousIndex].image
                  this.nextImage = slides[nextIndex].image
                }, 90)
							}
						}
					}
				}
			}
		},
		methods: {
			srcset(image, ratio) {
				const that = this;
				let src = '';

				[300, 600, 900, 1600, 2000].forEach((width) => {
					src += `${that.$options.filters.resize(image, `${width}x${ratio ? Math.round(width / ratio) : 0}`)} ${width}w, `;
				});

				src = src.slice(0, -2);

				return src;
			},
      nextSlide() {
	      if (this.carouselSwiper && this.blok.items.length > 1) {
          this.carouselSwiper.slideTo(this.carouselSwiper.realIndex + 1 === this.blok.items.length ? 0 : this.carouselSwiper.realIndex + 1)
	      }
      },
      previousSlide() {
	      if (this.carouselSwiper && this.blok.items.length > 1) {
          this.carouselSwiper.slideTo(this.carouselSwiper.realIndex === 0 ? this.blok.items.length - 1 : this.carouselSwiper.realIndex - 1)
	      }
      },
			formatTitle(string) {
				const pieces = string.split('\n')
				return pieces.length === 1 ? string : `<span>${pieces.join('</span><br><span>')}</span>`
			}
		},
		computed: {
			aspectRatio () {
				return this.$vuetify.breakpoint.height > this.$vuetify.breakpoint.width ? 1.5 : 2.2;
			}
		},
		mounted() {
			(this.$refs.carousel).classList.add('mounted')
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/assets/scss/_variables.scss";

  $cutoff: 15%;

	section
	{
    display: flex;

    > .previous-slide,
    > .next-slide {
      width: 10%;
      position: relative;
      backface-visibility: hidden;
      text-align: center;
      cursor: pointer;
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        bottom: $cutoff;
        right: 0;
        left: 0;
        top: 0;
        opacity: .5;
        transition: opacity .2s;
      }

      > .v-image {
        position: absolute;
        bottom: $cutoff;
        top: 0;
        width: calc(100% + 20px);
        max-width: none;

        > .v-image {
          left: -10px;
          right: -10px;
        }

        ::v-deep > .v-image__image {
          filter: blur(3px);
          backface-visibility: hidden;
          position: absolute;
          top: 0;
        }
      }

      > .v-icon {
        position: absolute;
        bottom: $cutoff / 2;
        transform: translate(-50%, 2.5vw);
        font-size: 4vw;

        color: map-get($brand-colors, 'green');
      }

      &:hover {
        &:after {
          opacity: .25;
        }

        > .v-icon {
          color: map-get($brand-colors, 'blue');
        }
      }
    }

    > .previous-slide {
      &:after {
        background: map-get($brand-colors, 'blue-grey')
      }
    }

    > .next-slide {
      &:after {
        background: #fff;
      }
    }

    > .swiper {
      width: 80%;
      border: {
        left: 4px solid #fff;
        right: 4px solid #fff;
      }

      > div {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 0;
        background: map-get($blue-grey, 'darken-3');

        padding-bottom: (100 / 2.2) * 1%;

        @media (orientation: portrait) {
          padding-bottom: (100 / 1.5) * 1%;
        }

        &.mounted .swiper-slide .body {
          opacity: 1;
        }

        .swiper-wrapper {
          height: auto;
        }

        .swiper-slide {
          height: 100%;
          position: relative;

          .v-img {
            height: 100%;
            width: 100%;

            position: absolute;
            display: block;

            object-fit: cover;

            top: 50%;
            left: 50%;

            transform: translate(-50%, -50%);
          }

          .body
          {
            position: relative;
            z-index: 5;
            color: #fff;
            opacity: 0;
            transition: opacity .25s;

            h1, h3
            {
              color: #fff;
              line-height: 1.1;
              margin-bottom: .5rem;
              text-shadow: 1px 1px rgba(0, 0, 0, .2);
            }

            h1 {
              ::v-deep > span {
                &:first-child {
                  color: #e2e2e2;
                }
              }
            }

            p {
              line-height: 1.5;
            }

            @media (min-width: map-get($grid-breakpoints, 'md'))
            {
              display: flex;
              align-items: center;
            }

            @media (max-height: 499px)
            {
              margin: 10vw;
              max-width: 80vw;

              @media (min-width: map-get($grid-breakpoints, 'xs'))
              {
                h1 {
                  font-size: 2.5rem;
                }
              }
            }

            @media (min-height: 500px)
            {
              position: absolute;
              right: $gutter-width * 4;
              left: $gutter-width * 4;
              bottom: $gutter-width * 2;

              @media (min-width: 480px)
              {
                h1
                {
                  font-size: 3rem;
                }

                h3
                {
                  font: {
                    weight: normal;
                    size: 1.5rem;
                  }
                }
              }

              @media (min-width: map-get($grid-breakpoints, 'lg'))
              {
                h1
                {
                  font-size: 4rem;
                }
              }
            }

            h3 + h3
            {
              margin-top: .25rem;
            }
          }
        }
      }
    }

    ::v-deep .swiper-pagination-bullet {
			opacity: .5;
			background: #fff;

			&-active {
				opacity: 1;
				background: $primary-color;
			}
		}

    // IE "FIXES"
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      > .previous-slide,
      > .next-slide {
        display: none;
      }

      > .next-slide,
      > .next-slide {
        display: none;
      }

      > .swiper {
        width: 100%;

        > div {
          overflow: hidden;

          .swiper-slide {
            width: 100% !important;

            .v-img {
              height: 400px;
              width: auto;
              max-width: 100%;
              max-height: 100%;

              position: relative;
              display: block;

              object-fit: fill;

              top: 0;
              left: 0;

              transform: none;
            }

          }
        }
      }
    }
	}
</style>

<template>
  <div v-editable="blok">
    <component
      :key="blok._uid"
      v-for="blok in blok.body"
      :blok="blok"
      :is="blok.component | dashify"
    />
  </div>
</template>

<script>
export default {
  props: ['blok'],

  data() {
  	return {
      showInvoiceBlock: this.$attrs.full_slug !== 'privacy-verklaring' && this.$attrs.full_slug !== 'cookies'
    }
  },
}
</script>
